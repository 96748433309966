import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>Un nou concepte ha vingut per quedar-se!</strong> Una nova manera de
                    posar-te a prova i, a més, fer-ho de manera personalitzada amb seguiment
                    mensual. Una sessió presencial d'una duració aproximada de 6 hores al nostre
                    centre de formació de Sant Cugat.
                </p>

                <p>
                    És una nova forma de treballar i veure com estàs evolucionant, però alhora
                    sortir de la sessió amb un resultat orientatiu sobre el teu moment actual per
                    poder treballar aspectes concrets.
                </p>

                <p>
                    El grup de treball es dividirà en 4 estacions diferents, i cada grup passarà per
                    totes elles durant aproximadament 1 hora i mitja per estació.
                </p>

                <ul className="space-y-5">
                    <li>
                        <strong>Estació 1: TEMARI</strong>
                        <p>
                            Simulacre de preguntes de temari general en 30-45 minuts. Aquesta prova
                            és supervisada per un docent especialista en temari de{" "}
                            <strong>POLICIES LOCALS</strong>. Un cop acabat el simulacre, es
                            corregirà i sabràs la teva puntuació. A més, el docent repassarà cada
                            pregunta, explicant el perquè de cada resposta i analitzant possibles
                            preguntes relacionades. Una manera d'<strong>estudiar ideal</strong>.
                        </p>
                    </li>

                    <li>
                        <strong>Estació 2: PSICOS</strong>
                        <p>
                            Simulacre psicotècnic oficial en 30-45 minuts, supervisat per un docent
                            especialista. Es corregiran les respostes i es resoldran dubtes sobre
                            com afrontar els exercicis psicotècnics.
                        </p>
                    </li>

                    <li>
                        <strong>Estació 3: FÍSIQUES</strong>
                        <p>
                            Prova supervisada per un formador tècnic especialista, que donarà
                            consells per afrontar les proves. Es realitzarà un simulacre de circuit,
                            press banca, cursa navette i altres possibles proves com salt, 50
                            metres, etc. Aquesta estació té una duració més curta per permetre temps
                            per a una dutxa ràpida abans de continuar.
                        </p>
                        <p>Tots els participants rebran una fitxa amb els resultats obtinguts.</p>
                    </li>

                    <li>
                        <strong>Estació 4: CASOS PRÀCTICS</strong>
                        <p>
                            Treball de resolució de casos pràctics per escrit, oralment en una
                            entrevista i amb la possibilitat de fer un <strong>role play</strong>.
                            Es cobriran totes les opcions per resoldre un cas pràctic d'una actuació
                            policial d'inici a fi.
                        </p>
                    </li>
                </ul>

                <p>
                    <strong>En resum:</strong> 6 hores de treball <strong>molt intens</strong> on
                    podràs preparar <strong>totes les parts</strong> de l'oposició en una sola
                    tarda. Tot això en un entorn espectacular i amb un equip de docents d'alt
                    nivell, tots policies i especialistes en les seves matèries.
                </p>

                <p>
                    Les estacions i sessions seran adaptatives: a mesura que avanci l'oposició,
                    introduirem noves estacions per preparar la fase de test de personalitat,
                    biodata, entrevista i qualsevol altra prova necessària.
                </p>

                <p>
                    <strong>
                        Vols posar-te a prova amb un grup de companys i companyes increïble i
                        assumir el teu objectiu?
                    </strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Posa't a prova com mai abans! Un entrenament intensiu i personalitzat de 6 hores per superar totes les fases de l'oposició a policies locals: temari, psicotècnics, físiques i casos pràctics. Amb docents especialitzats i un seguiment detallat, t'ajudem a arribar al teu màxim rendiment. Acceptes el repte?"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
